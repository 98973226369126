import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Typography  from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import { Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';

import { contractOShmkl, getTractorStats } from  '../utils/web3.js'

export const StatsTractors = ({ connectedWallet }) => {
    const [errorLoadingStats, setErrorLoadingStats] = useState(false)
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [updateInProgress, setUpdateInProgress] = useState(false)
    const [totalTractors, setTotalTractors] = useState(0)
    const [assignedTractors, setAssignedTractors] = useState(0)

    //Refresh stats if wallet is changed
    useEffect(()=>{
        setIsDataLoaded(false)
    },[connectedWallet])
    
    const updateTractorStats = async () => {
        try {
            getTractorStats(connectedWallet).then(stats=>{
                setTotalTractors(stats.total)
                setAssignedTractors(stats.assigned)
            })
        } catch (error) {
            setErrorLoadingStats(true)
        }
    } 

    useEffect(() => {
        // Define event handlers
        const handleTractorAssigned = (farmerId, tractorId, tractorOwner) => {
            if (tractorOwner === connectedWallet.address) {
                console.log("Tractor assigned", farmerId, tractorId, tractorOwner);
                setErrorLoadingStats(false);
                setUpdateInProgress(true);
                updateTractorStats().then(() => {
                    setUpdateInProgress(false);
                });
            }
        };
    
        const handleTractorRemoved = (farmerId, tractorId, tractorOwner) => {
            if (tractorOwner === connectedWallet.address) {
                console.log("Tractor removed", farmerId, tractorId, tractorOwner);
                setErrorLoadingStats(false);
                setUpdateInProgress(true);
                updateTractorStats().then(() => {
                    setUpdateInProgress(false);
                });
            }
        };
        
        //Cleanup 
        contractOShmkl.off('TractorAssigned', handleTractorAssigned);
        contractOShmkl.off('TractorRemoved', handleTractorRemoved);
        // Subscribe to events
        contractOShmkl.on('TractorAssigned', handleTractorAssigned);
        contractOShmkl.on('TractorRemoved', handleTractorRemoved);
    
    }, []);
    
    if(!isDataLoaded) {
        updateTractorStats().then(x=> {
            setIsDataLoaded(true)
        })
    }
    
    return ( 
        <Card sx={{
            maxWidth: { xs: '100%', sm: '60%', md: '30%', lg: '30%', xl: '10vw' },
            minWidth: { xs: '80%', sm: '60%', md: '40%', lg: '30%', xl: '10vw' },
            m: 0,
            textAlign: 'center',
            backgroundColor: '#e6e6e6',
            my: '2vh'
        }} elevation={0}>

            {(!isDataLoaded || updateInProgress) && <LinearProgress />}
            <CardContent sx={{
                px: '0.5vw'
            }}>
                <Typography variant="customVariant" component="div">
                    My Tractors
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {(isDataLoaded) && <>
                        <Typography variant={"h6"} sx={{ mb: 0 }}>{totalTractors}</Typography>
                        <Typography variant={"customVariant"} sx={{ mb: 1 }} gutterBottom> {assignedTractors} assigned Tractors</Typography>
                    </>}
                    {(totalTractors === 0) && <Link 
                        to={'/modearn-tractors'} 
                        component={RouterLink}
                        variant="customVariantFooterLink"
                    >{"Mint a Tractor"}</Link>}
                    {(errorLoadingStats) && <Alert severity="error">Stats could not be loaded.</Alert>}
                </Box>
            </CardContent>
        </Card>
    );
}

StatsTractors.propTypes = {
    connectedWallet: PropTypes.object
}