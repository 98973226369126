import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton'
import { isNetworkValid, promptNetworkSwitch } from  '../utils/web3.js'

export const WrongNetwork = () => {
    const [open, setOpen] = React.useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    if(!isDataLoaded) {
        isNetworkValid().then(isValid => {
            if(!isValid) {
                setOpen(true)
            }
            setIsDataLoaded(true)
        })
    }

    useEffect(() => {
        const handleChainChange = (chainId) => {
            isNetworkValid().then(isValid => {
                if(!isValid) {
                    setOpen(true)
                } else {
                    setOpen(false)
                }
                setIsDataLoaded(true)
            })
        };
        
        //Cleanup if needed, resubscribe
        window.ethereum.off('chainChanged', handleChainChange)
        window.ethereum.on('chainChanged', handleChainChange)
    }, []);


    //If the user "esc"s the modal without changing the network - check again and enfore the modal
    const handleClose = () => {
      isNetworkValid().then(isValid => {
          if(!isValid) {
              setOpen(true)
          } 
      })
    };

    return(<Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Wrong Network Detected
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            To use ModearnFinance dApp, you must switch to the Mode Network blockchain.
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={promptNetworkSwitch}>
            Add and switch to Mode Network
          </LoadingButton>
        </DialogActions>
      </Dialog>)
}