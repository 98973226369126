import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Typography  from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import { mint, getEthBalance } from '../utils/web3.js'

export const MintQuantityPicker = ({ limit: initialLimit, connectedWallet, updateTokenBalances, collection }) => {
    const [quantity, setQuantity] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [limit, setLimit] = useState(initialLimit)

    useEffect(()=>{
        getEthBalance(connectedWallet.address, collection).then(balance => {
            console.log(balance)
            if(balance.balance === 0) {
                setDisabled(true)
            }
            setLimit(balance.limit)
        })
    },[])
    
    const increase = () => {
        let newQuantity = quantity
        newQuantity++
        if(newQuantity <= limit) {
            setQuantity(newQuantity)
        }
    }
    const decrease = () => {
        let newQuantity = quantity - 1
        if(newQuantity > 0) {
            setQuantity(newQuantity)
        }
    }

    const mintTokens = async (collection) => {
        setIsLoading(true)
        mint(quantity, collection).then(result=>{
            if(result === 1) {
                updateTokenBalances(connectedWallet)
            }
            setIsLoading(false)
        })
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent:'center', width:'100%'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', my: '2vh', justifyContent:'center'}}>
                <Button onClick={() => decrease()} variant={'outlined'}> - </Button>
                <Typography sx={{mx: '2vw'}} variant={'h4'}>{quantity}</Typography>
                <Button onClick={()=>increase()}  variant={'outlined'}> + </Button>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', my: '2vh', justifyContent:'center'}}>
                <LoadingButton onClick={()=>mintTokens(collection)} variant={'contained'} loading={isLoading} disabled={disabled}> Mint </LoadingButton>
            </Box>
        </Box>
    )
}
MintQuantityPicker.propTypes = {
    initialLimit: PropTypes.number,
    connectedWallet: PropTypes.object,
    updateTokenBalances: PropTypes.func,
    collection: PropTypes.string
}