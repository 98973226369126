import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'

import { MintProgress } from '../components/MintProgress.jsx'
import { MintBox } from '../components/MintBox.jsx'
import { FooterMenu } from '../components/FooterMenu.jsx'
import { WrongNetwork } from '../components/WrongNetwork.jsx'

import { connectWallet } from '../utils/web3.js'

export const Tractors = ({connectedWallet, maxSupply, tokensMinted, updateTokenBalances, isPaused, nftTokenBalance, collection}) => {
    const [loading, setLoading] = useState(false)
    return (<>
        <Box sx={
            {
                my: '3vh', 
                textAlign: 'center', 
                display:'flex', 
                flexDirection:'column', 
                justifyContent:'center', 
                mx: { xs: '5%', sm: '10%', md: '20%', lg: '30%' },
            }
        }>
            <MintProgress maxSupply={maxSupply}  collection={collection}/>
            {(connectedWallet.connected && !isPaused) && <MintBox connectedWallet={connectedWallet}  nftTokenBalance={nftTokenBalance} updateTokenBalances={updateTokenBalances} collection={collection}/> }
            {(!connectedWallet.connected && !isPaused) && <LoadingButton variant="contained" sx={{ ml: '10vw', mr: '10vw', my: '3vh'}} loading={loading} onClick={() => {
                        setLoading(true)
                        connectWallet('eth_requestAccounts').then(result=>{
                            setLoading(false)
                        })
                        }}>Connect wallet</LoadingButton>
                    }
        </Box>
        <FooterMenu />
        <WrongNetwork />
    </>)
}

Tractors.propTypes = {
    connectedWallet: PropTypes.object,
    tokensMinted: PropTypes.number,
    soldOut: PropTypes.bool,
    maxSupply: PropTypes.number,
    updateTokenBalances: PropTypes.func,
    isPaused: PropTypes.bool,
    nftTokenBalance: PropTypes.number,
    collection: PropTypes.string
}