import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Typography  from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';

import { SingleFarmer } from './SingleFarmer.jsx'
import { getMyFarmers, getFirstAvailableTractor, contractOShmkl, getOShmklBalance, oShmklDecimals } from  '../utils/web3.js'

export const MyFarmers = ({ connectedWallet, currentBlockNumber }) => {
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [myFarmers, setMyFarmers] = useState([])
    const [stakedFarmers, setStakedFarmers] = useState([])
    const [notStakedFarmers, setNotStakedFarmers] = useState([])
    const [oShmklBalance, setOShmklBalance] = useState(0)

    useEffect(()=>{
        setIsDataLoaded(false)
    },[connectedWallet])

    useEffect(() => {
        const handleFarmerHarvest = (eventFarmerId, farmerOwner, harvestAmount, newBalance) => {
            if(farmerOwner === connectedWallet.address) {
                setOShmklBalance(newBalance)
            }
        };

        contractOShmkl.off('FarmerHarvest', handleFarmerHarvest);
        contractOShmkl.on('FarmerHarvest', handleFarmerHarvest);
    }, [])
    
    
    if(!isDataLoaded) {
        getOShmklBalance(connectedWallet).then(balance => {
            setOShmklBalance(balance*10**oShmklDecimals)
        })
        getMyFarmers(connectedWallet).then( allFarmers => {
            let farmers = []
            getFirstAvailableTractor(connectedWallet).then(tractorId => {
                if(allFarmers.length > 0) {
                    let i = 0
                    for(const singleFarmerData of allFarmers) {
                        farmers.push(<SingleFarmer farmerDetails={singleFarmerData} currentBlockNumber={currentBlockNumber} firstAvailableTractor={tractorId} connectedWallet={connectedWallet} oShmklBalance={oShmklBalance} key={i}/>)
                        i++
                    }
                }
                setMyFarmers(farmers)
    
                //Filter items, just in case we want to provide filters in the interface - to allow displaying all/staked only/not staked only
                let stakedFarmersFilter = allFarmers.filter(farmer => farmer.is_staked)
                let notStakedFarmersFilter = allFarmers.filter(farmer => !farmer.is_staked)
    
                setStakedFarmers(stakedFarmersFilter)
                setNotStakedFarmers(notStakedFarmersFilter)
                setIsDataLoaded(true)
            })
        })
    }

    return (
        <Box sx={{
            width: '100%',
            my: '3vh',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            mx: { xs: '5%', sm: '10%', md: '20%', lg: '25%' },
       }}>                
           <Paper elevation={15} style={{ width: '100%', margin: '20px', padding: '20px', textAlign: 'center' }}>
               <Typography variant="h5" gutterBottom>
                   SS Historia Terminal - Planet Mode
               </Typography>
               <Typography variant="h3" gutterBottom>
                   My Farmers
               </Typography>
                <Typography variant="p" paragraph>Manage your farm and harvest oSHMKL.</Typography>
                {(!isDataLoaded) && <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', px: '5%', my: '2vw', alignItems: { xs: 'center', sm: 'center' }}}>
                        <Typography variant="p" paragraph>Loading Farmers</Typography>
                        <CircularProgress />
                    </Box>
                }
                {(isDataLoaded && myFarmers.length > 0) && <Box sx={{display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', px: '5%', my: '2vw', alignItems: { xs: 'center', sm: 'flex-start' },  flexWrap: 'wrap',}}>
                    {myFarmers}
                    </Box>
                }
                {(isDataLoaded && myFarmers.length === 0) && <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', px: '5%', my: '2vw', alignItems: 'center',  flexWrap: 'wrap',}}>
                    <Typography variant="customVariantHeading" gutterBottom>
                        No farmers detected.
                    </Typography>
                    <Typography sx={{  mb: '1vw' }} variant={"h6"} color="text.secondary" gutterBottom>
                        <Link 
                            to={'/modearn-farmers'} 
                            component={RouterLink}
                            sx={
                                {
                                    textDecoration: 'none',
                                    color: 'primary.main',
                                    '&:hover': {
                                        color: 'primary.dark',
                                    },
                                }
                            }
                        >{"Mint a Farmer"}</Link>
                    </Typography>
                    </Box>
                }
            </Paper>
        </Box>
    );
}

MyFarmers.propTypes = {
    connectedWallet: PropTypes.object,
    currentBlockNumber: PropTypes.number
}