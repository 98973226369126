import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Typography  from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import LoadingButton from '@mui/lab/LoadingButton'
import CardActions from '@mui/material/CardActions';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import CircleIcon from '@mui/icons-material/Circle';

import { contractOShmkl, removeTractorFromFarmer } from '../utils/web3.js'

export const SingleTractor = ({  tractorDetails: initTractorDetails }) => {
    const [updateInProgress, setUpdateInProgress] = useState(false)
    const [tractorDetails, setTractorDetails] = useState(initTractorDetails)
    const [isAssigned, setIsAssigned] = useState(initTractorDetails.is_assigned)
    const [farmerId, setFarmerId] = useState(initTractorDetails.farmer_id)
    const [releaseError, setReleaseError] = useState(false)

    const releaseTractor = async() => {
        setUpdateInProgress(true)
        setReleaseError(false)
        removeTractorFromFarmer(farmerId, tractorDetails.tractor_id).then(result=>{
            setUpdateInProgress(false)
            if(result === 0) {
                setReleaseError(true)
            }
        })
    }

    useEffect(() => {
        const handleTractorRemoved = (eventFarmerId, tractorId) => {
            if (Number(tractorId) === tractorDetails.tractor_id) {
                setIsAssigned(false);
                setFarmerId(0);
            }
        };
    
        const handleTractorAssigned = (eventFarmerId, tractorId) => {
            if (Number(tractorId) === tractorDetails.tractor_id) {
                setIsAssigned(true);
                setFarmerId(Number(eventFarmerId));
            }
        };
    
        const handleFarmerUnstaked = (eventFarmerId, farmerOwner) => {
            if (farmerId === Number(eventFarmerId)) {
                setIsAssigned(false);
                setFarmerId(0);
            }
        };
        
        contractOShmkl.off('TractorRemoved', handleTractorRemoved);
        contractOShmkl.off('TractorAssigned', handleTractorAssigned);
        contractOShmkl.off('FarmerUnstaked', handleFarmerUnstaked);

        contractOShmkl.on('TractorRemoved', handleTractorRemoved);
        contractOShmkl.on('TractorAssigned', handleTractorAssigned);
        contractOShmkl.on('FarmerUnstaked', handleFarmerUnstaked);
    
    }, [tractorDetails, farmerId]);

    return ( 
        <Card sx={{
            maxWidth: { xs: '100%', sm: '60%', md: '30%', lg: '30%', xl: '17vw' },
            minWidth: { xs: '80%', sm: '60%', md: '40%', lg: '30%', xl: '15vw' },
            m: 0,
            textAlign: 'left',
            backgroundColor: '#e6e6e6',
            my: '2vh'
        }} elevation={0}>
            <CardMedia
                component="img"
                image={tractorDetails.artwork_thumbnail}
                alt="Modern Finance Tractor"
            />
            {(updateInProgress) && <LinearProgress />}
            <CardContent sx={{
                px: '0.5vw'
            }}>
                <Typography variant="h6" component="div">
                    Tractor #{tractorDetails.tractor_id}
                </Typography>
                <Typography sx={{  mb: '1vw' }} variant={"h6"} color="text.secondary" gutterBottom>
                    <CircleIcon sx={{ fontSize: '1.5rem', color: isAssigned ? 'green' : 'red', mr: '0.5vw' }} alt={"Assigned"}/>                    
                </Typography>  
                {(farmerId > 0) && <Typography variant={"customVariantHeading"} sx={{ mb: 1 }} gutterBottom>Assigned to Farmer #{farmerId}</Typography>}
                {(releaseError) && <Alert severity="error">Transaction canceled or something went wrong.</Alert>}          
            </CardContent>
            <CardActions>
                {(!updateInProgress) && <>
                    {(isAssigned) && <LoadingButton size="small" disabled={updateInProgress} onClick={()=>releaseTractor()}>Unassign this Tractor</LoadingButton>}
                </>}
           </CardActions>
        </Card>
    );
}

SingleTractor.propTypes = {
    tractorDetails: PropTypes.object
}