import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Typography  from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';

import { getOShmklBalance, provider, contractOShmkl, oShmklDecimals, getMyFarmersHarvestDetails, harvestAllFarmers } from  '../utils/web3.js'

export const StatsBalance = ({ connectedWallet }) => {
    const [updateInProgress, setUpdateInProgress] = useState(false)
    const [errorLoadingBalance, setErrorLoadingBalance] = useState(false)
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [oSHMKLBalance, setBalance] = useState(0)
    const [farmers, setFarmers] = useState([])
    const [canHarvestAll, setCanHarvestAll] = useState(false)
    const [currentBlockNumber, setCurrentBlockNumber] = useState(0)

    useEffect(()=>{
        setIsDataLoaded(false)
    },[connectedWallet])

    /**
     * (DONE) When stats are loaded, an array of all staked farmers and their harvest blocks is loaded as well.
     * 
     * (DONE) When a farmer is unstaked, this array is loaded again.
     * (DONE) When a farmer is staked, this array is loaded again.
     * (DONE) When a farmer is harvested, this array is loaded again.
     * (DONE)When a tractor is assigned, this array is loaded again.
     * (DONE) When a tractor is unassigned, this array is loaded again.
     * When harvest all transaction is completed, this array is loaded again.
     * 
     * (DONE) On each new block, the array is checked to see if there are 2 or more farmers ready for harvesting.
     * If there are two or more farmers ready to be harvested, harvest all link is displayed.
     * 
     */

    useEffect(() => {
        // Define event handlers
        const handleFarmerHarvest = (farmerId, farmerOwner, harvestAmount, newBalance) => {
            if (farmerOwner === connectedWallet.address) {
                console.log("New harvest", farmerId, farmerOwner, harvestAmount, newBalance);
                let balance = Number(newBalance) / 10 ** oShmklDecimals;
                setBalance(balance);
    
                getMyFarmersHarvestDetails(connectedWallet).then(stakedFarmers => {
                    setFarmers(stakedFarmers);
                });
            }
        };
    
        const handleFarmerUnstaked = (farmerId, farmerOwner) => {
            if (farmerOwner === connectedWallet.address) {
                getMyFarmersHarvestDetails(connectedWallet).then(stakedFarmers => {
                    setFarmers(stakedFarmers);
                });
            }
        };
    
        const handleFarmerStaked = (farmerId, farmerOwner) => {
            if (farmerOwner === connectedWallet.address) {
                getMyFarmersHarvestDetails(connectedWallet).then(stakedFarmers => {
                    setFarmers(stakedFarmers);
                });
            }
        };
    
        const handleTractorRemoved = (farmerId, tractorId, tractorOwner) => {
            if (tractorOwner === connectedWallet.address) {
                getMyFarmersHarvestDetails(connectedWallet).then(stakedFarmers => {
                    setFarmers(stakedFarmers);
                });
            }
        };
    
        const handleTractorAssigned = (farmerId, tractorId, tractorOwner) => {
            if (tractorOwner === connectedWallet.address) {
                getMyFarmersHarvestDetails(connectedWallet).then(stakedFarmers => {
                    setFarmers(stakedFarmers);
                });
            }
        };
    
        const handleNewBlock = (blockNumber) => {
            setCurrentBlockNumber(blockNumber)
        };
        
        const handleFertilizer = (farmerId, farmerOwner, harvestableAmount,newFarmerOwnerBalance) => {
            if(connectedWallet.address === farmerOwner) {
                let convertedBalance = Number(newFarmerOwnerBalance) / 10**oShmklDecimals
                setBalance(convertedBalance)
            }
        }

        const handleWater = (farmerId, farmerOwner, harvestableAmount,newFarmerOwnerBalance) => {
            if(connectedWallet.address === farmerOwner) {
                let convertedBalance = Number(newFarmerOwnerBalance) / 10**oShmklDecimals
                setBalance(convertedBalance)
            }
        }
        //Cleanup previous connections if any
        contractOShmkl.off('FarmerProvidedFertilizer', handleFertilizer);
        contractOShmkl.off('FarmerProvidedWater', handleWater);
        
        contractOShmkl.off('FarmerHarvest', handleFarmerHarvest);
        contractOShmkl.off('FarmerUnstaked', handleFarmerUnstaked);
        contractOShmkl.off('FarmerStaked', handleFarmerStaked);
        contractOShmkl.off('TractorRemoved', handleTractorRemoved);
        contractOShmkl.off('TractorAssigned', handleTractorAssigned);
        provider.off('block', handleNewBlock);

        // Subscribe to events
        contractOShmkl.on('FarmerHarvest', handleFarmerHarvest);
        contractOShmkl.on('FarmerUnstaked', handleFarmerUnstaked);
        contractOShmkl.on('FarmerStaked', handleFarmerStaked);
        contractOShmkl.on('TractorRemoved', handleTractorRemoved);
        contractOShmkl.on('TractorAssigned', handleTractorAssigned);
        contractOShmkl.on('FarmerProvidedFertilizer', handleFertilizer);
        contractOShmkl.on('FarmerProvidedWater', handleWater);
        provider.on('block', handleNewBlock);
    }, []);

    useEffect(() => {
        updateHarvestDetails().then(() => {});
    }, [farmers, currentBlockNumber]);
    
    
    const updateHarvestDetails = async() => {
        let canHarvestNow = 0
        if(farmers.length > 2 && !canHarvestAll) { //No need to check anything if canHarvestAll is already true
            for (const farmer of farmers) {
                if(farmer.next_harvest_block < currentBlockNumber) {
                    canHarvestNow++
                }
            }
        }
        if(canHarvestNow >= 2 && !canHarvestAll) {
            setCanHarvestAll(true)
        } 
    }


    const refreshBalance = async () => {
        getOShmklBalance(connectedWallet).then(balance=>{
            setErrorLoadingBalance(false)
            if(balance >= 0) {
                setBalance(balance)
            } else {
                setBalance(0)
                setErrorLoadingBalance(true)
            }
            setIsDataLoaded(true)
        })
    }
    if(!isDataLoaded) {
        getMyFarmersHarvestDetails(connectedWallet).then(stakedFarmers => {
            setFarmers(stakedFarmers)
            refreshBalance().then(x=>{})
        })
    }
    
    const harvestAll = async() => {
        setErrorLoadingBalance(false)
        setUpdateInProgress(true)
        harvestAllFarmers().then(result=>{
            if(result === 1) {
                getMyFarmersHarvestDetails(connectedWallet).then(stakedFarmers => {
                    setFarmers(stakedFarmers)
                    setCanHarvestAll(false)
                    setUpdateInProgress(false)
                })
            } else {
                setErrorLoadingBalance(true)
                setUpdateInProgress(false)
            }
        })
    }
    return ( 
        <Card sx={{
            maxWidth: { xs: '100%', sm: '60%', md: '30%', lg: '30%', xl: '10vw' },
            minWidth: { xs: '80%', sm: '60%', md: '40%', lg: '30%', xl: '10vw' },
            m: 0,
            textAlign: 'center',
            backgroundColor: '#e6e6e6',
            my: '2vh'
        }} elevation={0}>
            {(!isDataLoaded || updateInProgress) && <LinearProgress />}
            <CardContent sx={{
                px: '0.5vw'
            }}>
                <Typography variant="customVariant" component="div">
                    My Balance
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {(isDataLoaded) && <>
                        <Typography variant={"h6"} sx={{ mb: 0 }}>{oSHMKLBalance.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 5})}</Typography>
                        <Typography variant={"customVariant"} sx={{ mb: 1 }} gutterBottom> oSHMKL</Typography>
                    </>}
                    {(canHarvestAll) && <LoadingButton size="small" onClick={()=>harvestAll()} disabled={updateInProgress} >Harvest All</LoadingButton>}

                    {(errorLoadingBalance) && <Alert severity="error">oSHMKL balance could not be loaded</Alert>}
                </Box>
            </CardContent>
        </Card>
    );
}

StatsBalance.propTypes = {
    connectedWallet: PropTypes.object
}