import React from 'react'
import { Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box'
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography  from '@mui/material/Typography'

export const FooterMenu = () => {
    return (<>
        <Container sx={{
            paddingLeft: '15%',
            paddingRight: '15%',
            width: '100%', 
            mx: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Box sx={{
                 width: '100%',
                 my: '1vh',
                 textAlign: 'center',
                 display: 'flex',
                 flexDirection: 'column',
                 justifyContent: 'center',
                 alignItems: 'center',
                 mx: { xs: '5%', sm: '10%', md: '20%', lg: '25%' },
                 
            }}>                
                <Paper elevation={0} style={{ width: '100%', mx: '20px', padding: '20px', height:'50px', textAlign: 'center' }}>
                    <Box sx={{display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', px: '5%', my: '1vw', alignItems: { xs: 'center', sm: 'flex-start' }}}>
                        <Typography sx={{  mb: '1vw' }} variant={"customVariantFooterLink"} color="text.secondary" gutterBottom>
                            <Link 
                                to={'/'} 
                                component={RouterLink}
                                sx={
                                    {
                                        textDecoration: 'none',
                                        color: 'primary.main',
                                        '&:hover': {
                                            color: 'primary.dark',
                                        },
                                    }
                                }
                            >{"Launchpad"}</Link>
                        </Typography>
                        <Typography sx={{  mb: '1vw' }} variant={"customVariantFooterLink"} color="text.secondary" gutterBottom>
                            <Link 
                                to={'/dashboard'} 
                                component={RouterLink}
                                sx={
                                    {
                                        textDecoration: 'none',
                                        color: 'primary.main',
                                        '&:hover': {
                                            color: 'primary.dark',
                                        },
                                    }
                                }
                            >{"Dashboard"}</Link>
                        </Typography>
                        <Typography sx={{  mb: '1vw' }} variant={"customVariantFooterLink"} color="text.secondary" gutterBottom>
                            <Link 
                                to={'/modearn-farmers'} 
                                component={RouterLink}
                                sx={
                                    {
                                        textDecoration: 'none',
                                        color: 'primary.main',
                                        '&:hover': {
                                            color: 'primary.dark',
                                        },
                                    }
                                }
                            >{"Mint a Farmer"}</Link>
                        </Typography>
                        <Typography sx={{  mb: '1vw' }} variant={"customVariantFooterLink"} color="text.secondary" gutterBottom>
                            <Link 
                                to={'/modearn-tractors'} 
                                component={RouterLink}
                                sx={
                                    {
                                        textDecoration: 'none',
                                        color: 'primary.main',
                                        '&:hover': {
                                            color: 'primary.dark',
                                        },
                                    }
                                }
                            >{"Mint a Tractor"}</Link>
                        </Typography>
                    </Box>
                </Paper>
            </Box>
        </Container>
    </>)
}