import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Typography  from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box'
import {MintQuantityPicker} from './MintQuantityPicker.jsx'
import { mintDetails, getNftCost } from '../utils/web3.js'

export const MintBox = ({ connectedWallet, nftTokenBalance, updateTokenBalances, collection }) => {
    const [nftCost, setNFTCost] = useState(0)
    const [isDataLoaded, setIsDataLoaded] = useState(false)

    if(!isDataLoaded) {
        getNftCost(collection).then(cost => {
            setNFTCost(cost)
            setIsDataLoaded(true)
        })
    }
    return (<>
        <Card variant="outlined" sx={{textAlign: 'center'}}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {mintDetails[collection].mint_date}
                </Typography>
                <Typography variant="h5" component="div">
                    {mintDetails[collection].title}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {mintDetails[collection].description}
                </Typography>
                <Typography variant="body2">
                    {(isDataLoaded) && nftCost+" ETH / NFT"}
                </Typography>
                { <MintQuantityPicker limit={Number(mintDetails[collection].limit)} connectedWallet={connectedWallet} updateTokenBalances={updateTokenBalances} collection={collection}/>}
                <Box sx={{my: '2vh'}}>
                    <Typography>You own {nftTokenBalance} NFTs</Typography>
                </Box>
                <Box sx={{my: '2vh'}}>
                        <Typography>Connected as:</Typography>
                        <Typography>{connectedWallet.address}</Typography>
                        <Typography>Max Mint: {mintDetails[collection].limit} NFTs per transaction</Typography>
                </Box>
            </CardContent>
        </Card>
        </>
    )
}

MintBox.propTypes = {
    connectedWallet: PropTypes.object,
    nftTokenBalance: PropTypes.number,
    updateTokenBalances: PropTypes.func,
    collection: PropTypes.string
}