import React, { createContext, useContext, useState, useEffect } from 'react';
import { getMyTractors, contractOShmkl } from '../utils/web3.js'
const TractorContext = createContext();

export const TractorProvider = ({ children, connectedWallet }) => {
    const [tractors, setTractors] = useState([]);
    const [nextAvailableTractor, setNextAvailableTractor] = useState(0)

    useEffect(() => {
        updateTractors(connectedWallet).then(t=>{})
        
        const handleTractorStatusChange = (farmerId, tractorId, tractorOwner) => {
            if (tractorOwner === connectedWallet.address) {
                updateTractors(connectedWallet).then(t=>{})
            }
        }

        contractOShmkl.off('TractorRemoved', handleTractorStatusChange);
        contractOShmkl.off('TractorAssigned', handleTractorStatusChange);
        contractOShmkl.on('TractorRemoved', handleTractorStatusChange);
        contractOShmkl.on('TractorAssigned', handleTractorStatusChange);
    }, [connectedWallet])


    useEffect(() => {
        let nextTractorId = 0
        if(tractors.length > 0) {
            for (const tractor of tractors) {
                if(tractor.is_assigned === false) {
                    nextTractorId = tractor.tractor_id
                    break;
                }
            }
        } 
        setNextAvailableTractor(nextTractorId)
    }, [tractors])
    
    const updateTractors = async (connectedWallet) => {
        let myTractors = await getMyTractors(connectedWallet)
        setTractors(myTractors)
    }

    const reserveTractor = (tractorId) => {
        let newTractors = []
        for (const tractor of tractors) {
            if(tractor.tractor_id === tractorId) {
                tractor.is_assigned = true
            }
            newTractors.push(tractor)
        }
        setTractors(newTractors)
    }

    const releaseTractorReservation = (tractorId) => {
        let newTractors = []
        for (const tractor of tractors) {
            if(tractor.tractor_id === tractorId) {
                tractor.is_assigned = false
            }
            newTractors.push(tractor)
        }
        setTractors(newTractors)
    }

  return (
    <TractorContext.Provider value={{ tractors, nextAvailableTractor, reserveTractor, releaseTractorReservation }}>
      {children}
    </TractorContext.Provider>
  );
};

export const useTractors = () => useContext(TractorContext);
