import React, { useState, useEffect } from 'react'
import Typography  from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';

import { getOShmklSupplyStats, contractOShmkl } from  '../utils/web3.js'

export const StatsShmkl = () => {
    const [errorLoadingBalance, setErrorLoadingBalance] = useState(false)
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [totalSupply, setTotalSupply] = useState(0)
    const [maxSupply, setMaxSupply] = useState(0)

    useEffect(() => {
        // Define the event handler
        const handleFarmerHarvest = (farmerId, farmerOwner, harvestAmount, newBalance) => {
            getOShmklSupplyStats().then(supply => {
                setTotalSupply(supply.total);
                setMaxSupply(supply.max);
            });
        };
        contractOShmkl.off('FarmerHarvest', handleFarmerHarvest);
        contractOShmkl.on('FarmerHarvest', handleFarmerHarvest);
    }, []);
    


    if(!isDataLoaded) {
        getOShmklSupplyStats().then(supply=>{
            setTotalSupply(supply.total)
            setMaxSupply(supply.max)
            setIsDataLoaded(true)
        })
    }
    
    return ( 
        <Card sx={{
            maxWidth: { xs: '100%', sm: '60%', md: '30%', lg: '30%', xl: '10vw' },
            minWidth: { xs: '80%', sm: '60%', md: '40%', lg: '30%', xl: '10vw' },
            m: 0,
            textAlign: 'center',
            backgroundColor: '#e6e6e6',
            my: '2vh'
        }} elevation={0}>

            {(!isDataLoaded) && <LinearProgress />}
            <CardContent sx={{
                px: '0.5vw'
            }}>
                <Typography variant="customVariant" component="div">
                    oSHMKL Supply
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {(isDataLoaded) && <>
                        <Typography variant={"h6"} sx={{ mb: 0 }}>{totalSupply.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 5})}</Typography>
                        <Typography variant={"customVariant"} sx={{ mb: 1 }} gutterBottom> out of {maxSupply.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 5})} oSHMKL</Typography>
                    </>}
                    {(errorLoadingBalance) && <Alert severity="error">oSHMKL supply data could not be loaded</Alert>}
                </Box>
            </CardContent>
        </Card>
    );  
}
