import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, connectedWallet }) => {
  if (!connectedWallet.connected) {
    return <Navigate to="/" />;
  }
  return children;
}

export default ProtectedRoute;
