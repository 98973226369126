import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Typography  from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import {contract, contractTractors } from '../utils/web3.js'

export const MintProgress = ({ maxSupply, collection }) => {
    const [tokensMinted, setTokensMinted] = useState(0)
    const [isDataLoaded, setIsDataLoaded] = useState(false)

    if(!isDataLoaded) {
        if(collection === "farmers") {
            contract.totalSupply().then(x=>{
                setTokensMinted(Number(x))
                setIsDataLoaded(true)
            })
        } else if(collection === "tractors") {
            contractTractors.totalSupply().then(x=>{
                setTokensMinted(Number(x))
                setIsDataLoaded(true)
            })
        }
    }
    useEffect(() => {
        const handleFarmerMint = (tokenId, address, balance) => {
            if(collection === "farmers") {
                setTokensMinted(Number(tokenId))
            }
        }

        const handleTractorMint = (tokenId, address, balance) => {
            if(collection === "tractors") {
                setTokensMinted(Number(tokenId))
            }
        }

        contract.off('NewMint', handleFarmerMint);
        contractTractors.off('NewMint', handleTractorMint);
        contract.on('NewMint', handleFarmerMint);
        contractTractors.on('NewMint', handleTractorMint);
    }, []);
    
    return (
        <Card variant="outlined" sx={{textAlign: 'center'}}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Mint Progress
                </Typography>
                <Typography variant="h5" component="div">
                    {(tokensMinted < maxSupply) && <>{tokensMinted} / {maxSupply}</>}
                    {(tokensMinted === maxSupply) && <Typography variant={'h4'}>SOLD OUT!</Typography>}
                </Typography>
            </CardContent>
        </Card>
    )
}

MintProgress.propTypes = {
    maxSupply: PropTypes.number,
    collection: PropTypes.string
}