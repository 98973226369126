import PropTypes from 'prop-types'
import React from 'react'
import Typography  from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { SingleCard } from './SingleCard.jsx'

export const Welcome = ({ connectedWallet }) => {
    return (
        <>
            <Typography variant="p" paragraph>Welcome to Planet Mode's self-serving space-agro portal. Please choose your destination.</Typography>
            <Box sx={{display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', px: '5%', my: '2vw', alignItems: { xs: 'center', sm: 'flex-start' }}}>
                <SingleCard title={"Mint a Farmer"} desc={"oSHMKL is harvested by space-Farmers. Space Farmers are in charge of harvesting, and optimizing yield by provding water and fertilizer. "} icon={"mint-farmer"} gotoRoute={"/modearn-farmers"}/>
                <SingleCard title={"Mint a Tractor"} desc={"Every good Farmer needs a tractor. Tractors boost productivity and help farmers harvest oSHMKL faster."} icon={"mint-tractor"} gotoRoute={"/modearn-tractors"}/>
                <SingleCard title={"Farmer Dashboard"} desc={"Manage your oSHMKL farm by controling Farmers and Tractors. "} icon={"dashboard"} gotoRoute={"/dashboard"} />
            </Box>
            <Typography sx={{textAlign: 'right', fontSize: '0.85rem', fontWeight: 'bold'}} gutterBottom>
                Explorer #: 0x...{connectedWallet.address.slice(-5)}
            </Typography>
        </>
    );
}

Welcome.propTypes = {
    connectedWallet: PropTypes.object,
}