import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),

    customVariant: {
      fontSize: '.85rem',
      fontWeight: '500',
      color: '#000000',
    },
    customVariantFooterLink: {
      fontSize: '.85rem',
      fontWeight: '500',
      textDecoration: 'none'
    },
    customVariantHeading: {
      fontSize: '.85rem',
      fontWeight: '700',
      color: '#000000',
    },
  },
  palette: {
    primary: {
      main: '#9966ff',
      dark: '#115293',
    },
  },
});

export default theme;
