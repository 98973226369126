import PropTypes from 'prop-types'
import React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography  from '@mui/material/Typography'
import { Welcome } from '../components/Welcome.jsx'
import { Login } from '../components/Login.jsx'
import { WrongNetwork } from '../components/WrongNetwork.jsx'

export const Home = ({connectedWallet}) => {
    return (<>
        <Container sx={{
            paddingLeft: '15%',
            paddingRight: '15%',
            width: '100%',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Box sx={{
                 width: '100%',
                 my: '3vh',
                 textAlign: 'center',
                 display: 'flex',
                 flexDirection: 'column',
                 justifyContent: 'center',
                 alignItems: 'center',
                 mx: { xs: '5%', sm: '10%', md: '20%', lg: '25%' },
            }}>                
                <Paper elevation={15} style={{ width: '100%', margin: '20px', padding: '20px', textAlign: 'center' }}>
                    <Typography variant="h5" gutterBottom>
                        SS Historia Terminal
                    </Typography>
                    <Typography variant="h3" gutterBottom>
                        PLANET MODE
                    </Typography>
                    {(connectedWallet.connected) && <Welcome connectedWallet={connectedWallet} /> }
                    {(!connectedWallet.connected) && <Login /> }
                </Paper>
            </Box>
        </Container>
        <WrongNetwork />
    </>)
}

Home.propTypes = {
    connectedWallet: PropTypes.object
}