import './App.css';
import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import LinearProgress from '@mui/material/LinearProgress';
import { Home } from './pages/Home.jsx'
import { Farmers } from './pages/Farmers.jsx'
import { Tractors } from './pages/Tractors.jsx'
import { Dashboard } from './pages/Dashboard.jsx'
import { NotFound404 } from './pages/NotFound404.jsx'
import ProtectedRoute from './pages/ProtectedRoute'; // Import the ProtectedRoute component

import {
  getMaxSupply,
  getMaxSupplyTractors,
  getTokensMinted,
  getTokensMintedTractors,
  getIsPaused,
  getIsPausedTractors,
  connectWallet,
  getOwnersNftBalance,
  getOwnersNftBalanceTractors,
  contract,
  contractTractors
} from './utils/web3.js'

if (window.location.hostname !== 'localhost') {
  console.log = () => {}
}

function App() {
  // const [hasMetamask, setHasMetamask] = useState(true)
  const [connectedWallet, setConnectedWallet] = useState({address: null, connected: false})
  const [maxSupply, setMaxSupply] = useState(0)
  const [maxSupplyTractors, setMaxSupplyTractors] = useState(0)
  const [tokensMinted, setTokensMinted] = useState(0)
  const [tokensMintedTractors, setTokensMintedTractors] = useState(0)
  const [isPaused, setIsPaused] = useState(true)
  const [isPausedTractors, setIsPausedTractors] = useState(true)
  const [nftTokenBalance, setNftTokenBalance] = useState(0)
  const [nftTokenBalanceTractors, setNftTokenBalanceTractors] = useState(0)
  const [currentBlockNumber, setCurrentBlockNumber] = useState(0)
  const [loading, setLoading] = useState(true)
  
  //Once, on mount
  useEffect(()=>{

    if(!window.ethereum) {
      // setHasMetamask(false); // Leaving this here for now, may be useful later
      alert('Please install Metamask or similar wallet.')
    } else {
      getMaxSupply().then(result=>{
        setMaxSupply(result)
      })
      getMaxSupplyTractors().then(result=>{
        setMaxSupplyTractors(result)
      })
      getTokensMinted().then(result=>{
        setTokensMinted(result)
      })
      getTokensMintedTractors().then(result=>{
        setTokensMintedTractors(result)
      })
      getIsPaused().then(result => {
        setIsPaused(result)
      })

      getIsPausedTractors().then(result => {
        setIsPausedTractors(result)
      })
      
      //Previously connected wallets
      connectWallet('eth_accounts').then(result => {
        console.log('Accounts on reload', result)
        if(result.connected && result.address != null) {
          changeConnectedWallet(result).then(result => {
            console.log('Wallet re-connected', result)
            setLoading(false)
          })
        } else {
          setLoading(false)
        }
      })

      //Changing connected wallets
      window.ethereum.on('accountsChanged', (accounts)=>{
        if(accounts.length > 0) {
          console.log('Accounts changed', accounts)
          changeConnectedWallet({address: accounts[0], connected: true}).then(result => {
            console.log('Wallet changed', result)
          })
        } 
        else {
          setConnectedWallet({address: null, connected: false})
        }
      })
      
      //Update the mint counter on every NewMint event emitted by the contract
      contract.on("NewMint", (tokenId, address, balance ) => {
        setTokensMinted(Number(tokenId))
      })
      contractTractors.on("NewMint", (tokenId, address, balance ) => {
        setTokensMintedTractors(Number(tokenId))
      })
      
    }
  // eslint-disable-next-line
  },[])

  /**
   * Once the wallet is connected or changed, listen to any mint events to update the minter balance and propagate the change throughout the app
   */
  useEffect(()=>{
    contract.on("NewMint", (tokenId, address, balance ) => {
      if(connectedWallet.connected && connectedWallet.address.toLoweCase() === address.toLowerCase()) {
        console.log('Updating balance')
        setNftTokenBalance(Number(balance))
      }
    })
    contractTractors.on("NewMint", (tokenId, address, balance ) => {
      if(connectedWallet.connected && connectedWallet.address.toLoweCase() === address.toLowerCase()) {
        console.log('Updating balance')
        setNftTokenBalanceTractors(Number(balance))
      }
    })
  },[connectedWallet])

  
  /**
   * This method is triggered once a wallet change is detected.
   * It sets the connected wallet on the app level and fetches the NFT balance of the connected wallet.
   * 
   * @param {address, connected} wallet 
   * @returns 
   */
  const changeConnectedWallet = async(wallet) => {
    //Set connected wallet
    setConnectedWallet(wallet)
    await updateTokenBalances(wallet)
    await updateTokenBalancesTractors(wallet)
    return wallet
  }

  //System-wide update of balance 
  const updateTokenBalances = async(wallet) => {
    //Get the NFT balance of the connected wallet
    const newBalance = await getOwnersNftBalance(wallet.address);
    setNftTokenBalance(newBalance)
  }

  const updateTokenBalancesTractors = async(wallet) => {
    //Get the NFT balance of the connected wallet
    const newBalance = await getOwnersNftBalanceTractors(wallet.address);
    setNftTokenBalanceTractors(newBalance)
  }


  if(loading) {
    return (<LinearProgress />)
  }
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
            <Route path={'/'} element={<Home connectedWallet={connectedWallet} />} />
            <Route path={'/modearn-farmers'} element={<Farmers updateTokenBalances={updateTokenBalances} nftTokenBalance={nftTokenBalance} isPaused = {isPaused} connectedWallet={connectedWallet} maxSupply={maxSupply} tokensMinted={tokensMinted} changeConnectedWallet={changeConnectedWallet} collection={"farmers"}/>} />
            <Route path={'/modearn-tractors'} element={<Tractors updateTokenBalances={updateTokenBalancesTractors} nftTokenBalance={nftTokenBalanceTractors} isPaused = {isPausedTractors} connectedWallet={connectedWallet} maxSupply={maxSupplyTractors} tokensMinted={tokensMintedTractors} changeConnectedWallet={changeConnectedWallet} collection={"tractors"}/>}/>
            <Route path={'/dashboard'} element={
              <ProtectedRoute connectedWallet={connectedWallet}>
                <Dashboard connectedWallet={connectedWallet} currentBlockNumber={currentBlockNumber}/>
              </ProtectedRoute>} />
            <Route path={"*"} element={<NotFound404 />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
