import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import Typography  from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper';
import { StatsBalance } from './StatsBalance.jsx'
import { StatsFarmers } from './StatsFarmers.jsx'
import { StatsTractors } from './StatsTractors.jsx'
import { StatsShmkl } from './StatsShmkl.jsx'

export const Stats = ({ connectedWallet }) => {

    useEffect(()=>{
    },[connectedWallet])

    return (
        <Box sx={{
            width: '100%',
            my: '3vh',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            mx: { xs: '5%', sm: '10%', md: '20%', lg: '25%' },
       }}>                
           <Paper elevation={15} style={{ width: '100%', margin: '20px', padding: '20px', textAlign: 'center' }}>
               <Typography variant="h5" gutterBottom>
                   SS Historia Terminal - Planet Mode
               </Typography>
               <Typography variant="h3" gutterBottom>
                   Stats
               </Typography>
                <Typography variant="p" paragraph>Planet Mode Stats</Typography>
                <Box sx={{display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', px: '5%', my: '2vw', alignItems: { xs: 'center', sm: 'flex-start' },  flexWrap: 'wrap',}}>
                    <StatsBalance connectedWallet={connectedWallet}/>
                    <StatsFarmers connectedWallet={connectedWallet}/>
                    <StatsTractors connectedWallet={connectedWallet}/>
                    <StatsShmkl />
                </Box>
                <Typography sx={{textAlign: 'right', fontSize: '0.85rem', fontWeight: 'bold'}} gutterBottom>
                    Explorer #: 0x...{connectedWallet.address.slice(-5)}
                </Typography>
            </Paper>
        </Box>
    );
}

Stats.propTypes = {
    connectedWallet: PropTypes.object
}