import { JsonRpcProvider, Contract, ethers } from 'ethers';
import Web3 from 'web3';

//Load smart contract ABIs and other config variables
export const farmersSmartContractAbi = require('../abis/MODEarnFarmers.json')
export const tractorsSmartContractAbi = require('../abis/MODEarnTractors.json')
export const oShmklSmartContractAbi = require('../abis/oSchmeckles.json')
export const mintConfig = require('../mint.config.json')

//Initialize blockchain provider and smart contract interfaces
export const smartContractAddress = mintConfig.smart_contract_address
export const tractorsSmartContractAddress = mintConfig.tractors_smart_contract_address
export const oShmklSmartContractAddress = mintConfig.oshmkl_smart_contract_address
export const providerUrl = mintConfig.provider_url
export const provider = new JsonRpcProvider(providerUrl);
export const contract = new Contract(smartContractAddress, farmersSmartContractAbi.abi, provider);
export const contractTractors = new Contract(tractorsSmartContractAddress, tractorsSmartContractAbi.abi, provider);
export const contractOShmkl = new Contract(oShmklSmartContractAddress, oShmklSmartContractAbi.abi, provider);
export const web3 = new Web3(providerUrl)

//Load settings from smart contracts
export const oShmklDecimals = mintConfig.oshmkl_decimals
export const costOfFertilizer = await contractOShmkl.getCostOfFertilizer();
export const costOfWater = await contractOShmkl.getCostOfWater(); 
export const tractorBoost = Number(await contractOShmkl.getTractorBoost());
export const defaultRewardCycle = Number(await contractOShmkl.getRewardCycle());

//Load required network information.
//This is required for properly determining if the user is connected to Mode Network blockchain
const chainEnv = mintConfig.chain_env
let chainDetails;
if(chainEnv === "testnet") {
    chainDetails = mintConfig.testnet_chain
} else {
    chainDetails = mintConfig.mainnet_chain
}
export const targetNetwork = chainDetails

//Some textual info displayed on mint pages.
//Mint components are re-used, hence this text config is required.
//Although this can be moved to the config.json file or to the component it self.
export const mintDetails = { 
    farmers: {
        title: "Mint a Farmer - Mint In Progress.",
        description: "Description text goes here",
        mint_date: "01 July 2024",
        limit: Number(200),
    },
    tractors: {
        title: "Mint a Tractor - Mint In Progress.",
        description: "Description text goes here",
        mint_date: "01 July 2024",
        limit: Number(200),
    }
}

/**
 * This method geths the browser provider (Metamask for example) and gets the chain ID.
 * Required chain ID for Mode Network is stored in the mint.config.js.
 * 
 * Not used anywhere at the moment, can be removed - but leaving it here in case it becomes needed while
 * building the final UI. If it is still unused at the end of the front-end development, it can be removed.
 * 
 * @returns 
 * 
 */
export const getChainId = async () => {
    if (window.ethereum) {
        const providerTmp = new ethers.BrowserProvider(window.ethereum);
        const { chainId } = await providerTmp.getNetwork();
        return chainId
    } else {
        return 0;
    }
}


/**
 * This method will check if the user is connected to Mode Network blockchain and return true or false.
 * 
 * The method gets the current chain ID from the Browser Provider (e.g. Metamask) and compares it to the defined
 * chain ID from the mint.config.js.
 * 
 * Please note that the target chain is specified as hexadecimal.
 * @returns boolean
 */
export const isNetworkValid = async () => {
    if (window.ethereum) {
        const providerTmp = new ethers.BrowserProvider(window.ethereum);
        const { chainId } = await providerTmp.getNetwork();
        if (Number(chainId) !== parseInt(targetNetwork.chainId, 16)) {
            return false
        } else {
            return true
        }
    } else {
        return false
    }
}


/**
 * This method will check if the user is connected to the Mode Network blockchain.
 * If not, the method will first check if the user has Mode Network added to their Metamask wallet.
 * If not, the method will prompt the user to add Mode Network to their Metamask.
 * 
 * This method is used by the WrongNetwork component.
 */
export const promptNetworkSwitch = async() => {
    if (window.ethereum) {
        const providerTmp = new ethers.BrowserProvider(window.ethereum);
        const { chainId } = await providerTmp.getNetwork();

        if (chainId !== parseInt(targetNetwork.chainId, 16)) {
            try {
                // Prompt the user to switch to the target network
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: targetNetwork.chainId }]
                });
            } catch (switchError) {
                // This error code indicates that the chain has not been added to MetaMask
                if (switchError.code === 4902) {
                    try {
                        // Prompt the user to add the new network
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [targetNetwork]
                        });
                    } catch (addError) {
                        console.error('Failed to add the network:', addError);
                    }
                } else {
                    console.error('Failed to switch the network:', switchError);
                }
            }
        } else {
            console.log('User is connected to the correct network.');
        }
    } else {
        console.error('MetaMask is not installed.');
    }
}

/**
 * This method is used for connecting the wallet to the Modearn Finance dapp.
 * The method takes the connectionMethod param which allows the method to either prompt the user to connect their wallet, or to get
 * the list of already connected wallets.
 * 
 * The method returns an object containing the address of the connected wallet and a boolean flag indicating that the wallet is connected.
 * 
 * @param {*} connectionMethod 
 * @returns 
 */
export const connectWallet = async (connectionMethod) => {
    let defaultConnectionStatus = { address: null, connected: false }
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: connectionMethod
            })
            if (addressArray.length > 0) {
                let walletConnected = { address: await web3.utils.toChecksumAddress(addressArray[0]), connected: true }
                return walletConnected
            } else {
                return defaultConnectionStatus
            }
        } catch (error) {
            console.error("Web3 error: ", error)
            return defaultConnectionStatus
        }
    } else {
        console.error("Web3 Error","Wallet not installed")
        return defaultConnectionStatus
    }
}

/**
 * This method is used to check how many NFTs can a connected wallet mint.
 * The method takes two arguments - a wallet address and a collection indicator (farmers or tractors).
 * The method will then fetch the price of a single NFT from the specified collection, and based on the wallets ETH balance
 * calculate how many NFTs can this wallet mint.
 * 
 * The method returns an object containing the balance and the limit - limit being the number of NFTs the owner has enough money for.
 * If the limit is 0 - the mint button will be disabled on the mint page.
 * If the number is greater than 0, the quantity picker on the mint page will only go as high as the limit.
 * 
 * @param {*} address 
 * @param {*} collection 
 * @returns 
 */
export const getEthBalance = async (address, collection) => {
    let balance = 0
    const txProvider = new ethers.BrowserProvider(window.ethereum)
    let txContract = new Contract(tractorsSmartContractAddress, tractorsSmartContractAbi.abi, txProvider);
    if(collection === "farmers") {
        txContract = new Contract(smartContractAddress, farmersSmartContractAbi.abi, txProvider);
    }    
    const cost = Number(await txContract.getPriceForQuantity(1))

    if (window.ethereum) {
        try {
            balance = Number(await web3.eth.getBalance(address));
        } catch (error) {
          console.error("Error fetching balance: ", error);
        }
    } else {
    console.error("Ethereum provider not found");
    }

    let limit = Math.floor((balance/cost))
    return {balance: balance, limit: limit};
}

/**
 * This method takes the eth address (wallet address) as the argument and returns a number of Modearn Farmers owned by this wallet.
 * 
 * @param {*} address 
 * @returns 
 */
export const getOwnersNftBalance = async (address) => {
    try {
        const balance = await contract.balanceOf(address)
        return Number(balance)
    } catch (error) {
        console.error("Web3 error", error)
    }
}

/**
 * This method takes the eth address (wallet address) as the argument and returns a number of Modearn Tractors owned by this wallet.
 * 
 * @param {*} address 
 * @returns 
 */
export const getOwnersNftBalanceTractors = async (address) => {
    try {
        const balance = await contractTractors.balanceOf(address)
        return Number(balance)
    } catch (error) {
        console.error("Web3 error", error)
    }
}

/**
 * This method returns the max supply of Modearn Farmers NFTs.
 * 
 * @returns 
 */
export const getMaxSupply = async () => {
    try {
        const maxSupply = Number(await contract.getMaxSupply());
        return maxSupply;
    } catch (error) {
        console.error('Web3 Error: ', error)
        return  -1
    }
}

/**
 * This method returns the max supply of Modearn Tractors NFTs.
 * 
 * @returns 
 */
export const getMaxSupplyTractors = async () => {
    try {
        const maxSupply = Number(await contractTractors.getMaxSupply());
        return maxSupply;
    } catch (error) {
        console.error('Web3 Error: ', error)
        return  -1
    }
}

/**
 * This method returns the number of Modearn Farmers minted.
 * 
 * @returns 
 */
export const getTokensMinted = async () => {
    try {
        const tokensMinted = Number(await contract.getTokensMinted())
        return tokensMinted
    } catch (error) {
            console.error("Web 3 error: ", error)
            return 0;
    }
}

/**
 * This method returns the number of Modearn Tractors minted.
 * 
 * @returns 
 */
export const getTokensMintedTractors = async () => {
    try {
        const tokensMinted = Number(await contractTractors.getTokensMinted())
        return tokensMinted
    } catch (error) {
            console.error("Web 3 error: ", error)
            return 0;
    }
}

/**
 * This method will check if the Modearn Tractors smart contract is paused or not.
 * 
 * @returns bool
 */
export const getIsPausedTractors = async () => {
    try {
        const isPaused = await contractTractors.isPaused();
        return isPaused
    } catch (error) {
        console.error("Web3 error:", error)
        return true;
    }
}

/**
 * This method will check if the Modearn Farmers smart contract is paused or not.
 * 
 * @returns bool
 */
export const getIsPaused = async () => {
    try {
        const isPaused = await contract.isPaused();
        return isPaused
    } catch (error) {
        console.error("Web3 error:", error)
        return true;
    }
}


/**
 * This method builds a connection towards a given smart contract.
 * The connection is required in order to emmit the transaction to the blockhain.
 * It returns both the connection and the contract instance.
 * 
 * @param {*} contract 
 * @returns 
 */
const getSignerConnection = async(contract) => {
    const txProvider = new ethers.BrowserProvider(window.ethereum)
    const signer = txProvider.getSigner()
    let txContract

    switch (contract) {
        case "farmers":
            txContract = new Contract(smartContractAddress, farmersSmartContractAbi.abi, txProvider);
            break;
        case "tractors":
            txContract = new Contract(tractorsSmartContractAddress, tractorsSmartContractAbi.abi, txProvider);
            break;
        default: 
            txContract = new Contract(oShmklSmartContractAddress, oShmklSmartContractAbi.abi, txProvider);
            break;
    }
    const connection = txContract.connect( await signer)
    return {connection, txContract}
}

/**
 * This method initiates the mint transaction for Modearn Farmers and Tractors.
 * The method will begin by getting an instance of the contract and the connection based on the collection argument.
 * THe method will then calculate the price of a single NFT and calculate the final cost of minting the desired qunatity of NFTs.
 * Quantity must be greater than 0.
 * 
 * @param {*} quantity 
 * @returns 
 */
export const mint = async (quantity, collection) => {
    if(quantity <  1) {
        return 0
    } else {
        const {connection, txContract} = await getSignerConnection(collection);
        const cost = await txContract.getPriceForQuantity(quantity)

        try {
            const tx = await connection.mint(quantity, {
                value: cost.toString()
            })
            const result = await tx.wait()
            console.log('TX completed',tx, result)
            return result.status
        } catch (error) {
            console.error('Web3 error', error)
            return 0
        }
    }
}

/**
 * This method returns a human-readable cost per NFT (e.g. 0.005) based on the collection argument.
 * 
 * @param {*} collection 
 * @returns 
 */
export const getNftCost = async (collection) => {
    const {connection, txContract} = await getSignerConnection(collection);
    const cost = await txContract.getPriceForQuantity(1)
    return (Number(cost)/10**18)
}


/**
 * What follows are methods that interact with oSHMKL smart contract.
 * These methods are used for staking/unstaking farmers, harvesting, assigning and removing tractors etc.
 * 
 * To avoid code repetition - buildAndSignTx method is used to prepare the transaction. This method utilizes
 * a switch to determine which method of the smart contract should be called.
 * 
 * Methods that follow, for example stakeFarmer() are wrappers that call buildAndSignTx. Although buildAndSignTx
 * could be called directly from the component - wrappers are used for potential future cutomizations or debugging.
 * 
 * 
 * @param {*} txMethod 
 * @param {*} farmerId 
 * @param {*} tractorId 
 * @returns 
 */
const buildAndSignTx = async (txMethod, farmerId = 0, tractorId = 0) => {
    const {connection, txContract} = await getSignerConnection();
    let tx;
    try {
        switch( txMethod) {
            case "removeTractor":
                tx = await connection.removeTractor(farmerId, tractorId, {})
                break;
            case "fertilize": 
                tx = await connection.fertilize(farmerId, {})
                break;
            case "water": 
                tx = await connection.water(farmerId, {})
                break;
            case "harvest":
                tx = await connection.harvest(farmerId, {})
                break;
            case "stakeAll":
                tx = await connection.stakeAll()
                break;
            case "harvestAllFarmers":
                tx = await connection.harvestAll()
                break;
            case "assignTractor":
                tx = await connection.assignTractor(farmerId, tractorId, {})
                break;
            case "unstake":
                tx = await connection.unstake(farmerId, {})
                break;
            case "stakeToken":
                tx = await connection.stakeToken(farmerId, {})
                break;
            default: 
                break;
        }
        const result = await tx.wait()
        return result.status
    } catch (error) {
        console.error(error)
        return 0
    }
}

/**
 * This method will initiate a transaction for staking a farmer.
 * It takes a single argument - farmerId, gets the connection to the contract, builds a transaction,
 * and prompts the user to sign and send the transaction to the blockchain.
 * 
 * @param {*} farmerId 
 * @returns 
 */
export const stakeFarmer = async  (farmerId) => {
    const result = await buildAndSignTx("stakeToken", farmerId)
    return result
}

/**
 * This method is used to build and prompt the user to emit a transaction that unstakes a farmer.
 * 
 * @param {*} farmerId 
 * @returns 
 */
export const unstakeFarmer = async  (farmerId) => {
    const result = await buildAndSignTx("unstake", farmerId)
    return result
}

/**
 * This method is used for building and sending a transaction that assigns the given tractorId to
 * the given farmerId.
 * 
 * Note: validation of the arguments is done by the smart contract, so no need to handle any validations in here.
 * 
 * 
 * @param {*} farmerId 
 * @param {*} tractorId 
 * @returns 
 */
export const assignTractorToFarmer = async  (farmerId, tractorId) => {
    const result = await buildAndSignTx("assignTractor", farmerId, tractorId)
    return result
}

/**
 * This method is used to build and sign a transaction that would remove/unassign a tractor from a farmer.
 * @param {*} farmerId 
 * @param {*} tractorId 
 * @returns 
 */
export const removeTractorFromFarmer = async  (farmerId, tractorId) => {
    const result = await buildAndSignTx("removeTractor", farmerId, tractorId)
    return result
}


export const provideFertilizer = async  (farmerId) => {
    const result = await buildAndSignTx("fertilize", farmerId)
    return result
}

export const provideWater = async  (farmerId) => {
    const result = await buildAndSignTx("water", farmerId)
    return result
}


export const harvestFarmer = async  (farmerId) => {
    const result = await buildAndSignTx("harvest", farmerId)
    return result
}

export const stakeAllFarmers = async  () => {
    const result = await buildAndSignTx("stakeAll")
    return result
}

export const harvestAllFarmers = async  () => {
    const result =  await buildAndSignTx("harvestAllFarmers")
    return result
}

/**
 * This method returns the human-readable oSHMKL balance of the connected wallet.
 * @param {*} connectedWallet 
 * @returns 
 */
export const getOShmklBalance = async (connectedWallet) => {
    try {
        let balance = await contractOShmkl.balanceOf(connectedWallet.address)
        let convertedBalance = Number(balance) / 10**oShmklDecimals
        return convertedBalance;
    } catch (error) {
        console.error(error)
        return -1
    }
}

/**
 * This method is used to fetch everything we need to know about a single farmer.
 * Based on the farmerId provided - it will fetch the information from the smart contract 
 * and return an object to the component.
 * 
 * Variable names are self-explanatory.
 * Responses from the smart contract are converted from BigInt to regular number.
 * 
 * @param {*} farmerId 
 * @returns 
 */
export const getSingleFarmerDetails = async (farmerId) => {
    try {
        let isStaked = await contractOShmkl.isFarmerStaked(farmerId)
        let stakedAtBlock = await contractOShmkl.getWhenStaked(farmerId)
        let nextHarvestBlock = await contractOShmkl.getNextHarvestBlock(farmerId)
        let harvestableAmount = await contractOShmkl.getHarvestableAmount(farmerId)
        let isWaterProvided = await contractOShmkl.isWaterProvided(farmerId)
        let isFertilizerProvidedThisCycle = await contractOShmkl.isFertilizerProvidedThisCycle(farmerId)
        let assignedTractorId = await contractOShmkl.getFarmersTractor(farmerId)
        let artworkThumbUrl = await getArtworkThumbnail(farmerId)
        let fertilizerBoost = await contractOShmkl.getFarmersFertilizerBoost(farmerId)
        let baseReward = await contractOShmkl.getBaseReward(farmerId)

        let farmerDetails = {
            farmer_id: Number(farmerId),
            is_staked: isStaked,
            staked_at_block: Number(stakedAtBlock),
            next_harvest_block: Number(nextHarvestBlock),
            harvestable_amount: Number(harvestableAmount)/10**oShmklDecimals,
            is_water_provided: isWaterProvided,
            is_fertilizer_provided: isFertilizerProvidedThisCycle,
            assigned_tractor_id: Number(assignedTractorId),
            artwork_thumbnail: artworkThumbUrl,
            fertilizer_boost: Number(fertilizerBoost),
            base_reward: Number(baseReward)/(10**oShmklDecimals)
        }
        return farmerDetails
    } catch (error) {
        console.error(error)
        throw new Error(error)
    }
}

/**
 * This method will find out the total number of farmers owned by the connected wallet, and then 
 * find out how many of them are staked.
 * 
 * It's used in the StatsFarmers component.
 * 
 * @param {*} connectedWallet 
 * @returns 
 */
export const getFarmerStats = async (connectedWallet) => {
    try {
        let totalFarmers = await contract.balanceOf(connectedWallet.address)
        let stakedFarmers = 0
        if(totalFarmers > 0) {
            for (let i = 0; i< totalFarmers; i++) {
                let farmerId = await contract.tokenOfOwnerByIndex(connectedWallet.address, i)
                let isStaked = await contractOShmkl.isFarmerStaked(farmerId)
                if(isStaked) {
                    stakedFarmers++
                }
            }
        }
        return {total: Number(totalFarmers), staked: stakedFarmers}
    } catch (error) {
        console.error(error)
        return {total: 0, staked: 0}
    }
}

/**
 * This method will check how many tractors the connected wallet owns, and how many of those tractors are assigned to farmers.
 * @param {*} connectedWallet 
 * @returns 
 */
export const getTractorStats = async (connectedWallet) => {
    try {
        let totalTractors = await contractTractors.balanceOf(connectedWallet.address)
        let assignedTractors = 0
        if(totalTractors > 0) {
            for (let i = 0; i< totalTractors; i++) {
                let tractorId = await contractTractors.tokenOfOwnerByIndex(connectedWallet.address, i)
                let isAssigned = await contractOShmkl.isTractorAssigned(tractorId)
                if(isAssigned) {
                    assignedTractors++
                }
            }
        }
        return {total: Number(totalTractors), assigned: assignedTractors}
    } catch (error) {
        console.error(error)
        return {total: 0, assigned: 0}
    }
}

/**
 * This method returns the information about the maxSupply of oSHMKl tokens and the number of 
 * oSHMKL tokens minted until now.
 * 
 * @returns 
 */
export const getOShmklSupplyStats = async () => {
    try {
        let totalSupply = await contractOShmkl.totalSupply()
        let maxOShmklSupply = await contractOShmkl.maxSupply()

        let total = Number(totalSupply)/10**oShmklDecimals
        let max = Number(maxOShmklSupply)/10**oShmklDecimals

        return {total: total, max: max}
    } catch (error) {
        console.error(error)
        return {total: 0, max: 0}
    }
}
/**
 * This method will fetch all farmers of a given wallet, and then for each farmer gather
 * essential information about staking.
 * 
 * It's used by the MyFarmers component.
 */
export const getMyFarmers = async (connectedWallet) => {
    try {
        let farmers = []
        let address = connectedWallet.address
        const balance = await contract.balanceOf(address)

        if(balance > 0) {
            for (let i = 0; i< balance; i++) {
                let farmerId = await contract.tokenOfOwnerByIndex(address, i)
                let farmerDetails = await getSingleFarmerDetails(farmerId)
                farmers.push(farmerDetails)
            }
        }
        return farmers
    } catch (error) {
        console.error(error)
        return []
    }
}


/**
 * This method is used by the StatsBalance component.
 * The goal of this method is to provide information about the farmers and their next harvest block.
 * This is important for determining when the "harvest all" button should be displayed.
 * 
 * Reminder - harvest all button should be displayed when two or more farmers are ready to harvest.
 * 
 * @param {*} connectedWallet 
 * @returns 
 */
export const getMyFarmersHarvestDetails = async (connectedWallet) => {
    try {
        let farmers = []
        let address = connectedWallet.address
        const balance = await contract.balanceOf(address)

        if(balance > 0) {
            for (let i = 0; i< balance; i++) {
                let farmerId = await contract.tokenOfOwnerByIndex(address, i)
                let isStaked = await contractOShmkl.isFarmerStaked(farmerId)
                let nextHarvestBlock = await contractOShmkl.getNextHarvestBlock(farmerId)
                let farmerDetails = {
                    farmer_id: Number(farmerId),
                    is_staked: isStaked,
                    next_harvest_block: Number(nextHarvestBlock),
                }
                if(isStaked) {
                    farmers.push(farmerDetails)
                }
            }
        }
        return farmers
    } catch (error) {
        console.error(error)
        return []
    }
}

/**
 * This method will first check how many tractors a given wallet address owns.
 * If the address ownes more than 0 tractors, the method will build an array containing details for each of the
 * tractors. The method is used in the MyTractors component.
 * 
 * 
 * @param {*} connectedWallet 
 * @returns 
 */
export const getMyTractors = async (connectedWallet) => {
    try {
        let tractors = []
        let address = connectedWallet.address
        const balance = await contractTractors.balanceOf(address)

        if(balance > 0) {
            for (let i = 0; i< balance; i++) {
                let tractorId = await contractTractors.tokenOfOwnerByIndex(address, i)

                let tractorDetails = await getSingleTractorDetails(tractorId)
                tractors.push(tractorDetails)
            }
        }
        return tractors
    } catch (error) {
        console.error(error)
        return []
    }
}


/**
 * This method is incomplete. It's blocked by the collection artwork.
 * However, once the collection artwork is finalized and uploaded - this method will fetch the tokens
 * metadata - and extract the URL to the token artwork (full res) and to the token thumbnail.
 * 
 * URL returned by this method is used in the SingleFarmer component.
 * 
 * @param {*} farmerId 
 * @returns 
 */
export const getArtworkThumbnail = async (farmerId) => {
    return "/farmer-demo.jpg"
}

export const getTractorArtworkThumbnail = async(tractorId) => {
    return "/tractor-demo.png"
}

/**
 * Based on the given tractorId, this method with build an objected containing the information about it.
 * The information includes in indicator if the tractor is assigned or not, artwork ULR, and the farmer ID
 * it is assigned to.
 * 
 * @param {*} tractorId 
 * @returns 
 */
export const getSingleTractorDetails = async(tractorId) => {
    try {
        let isAssigned = await contractOShmkl.isTractorAssigned(tractorId)
        let artworkThumbUrl = await getTractorArtworkThumbnail(tractorId)
        let farmerId = await contractOShmkl.getAssociatedFarmer(tractorId)

        let tractorDetails = {
            tractor_id: Number(tractorId),
            is_assigned: isAssigned,
            farmer_id: Number(farmerId),
            artwork_thumbnail: artworkThumbUrl
        }

        return tractorDetails
    } catch (error) {
        console.error(error)
        throw new Error(error)
    }
}

/**
 * This method is used for finding the first available (unassigned) tractor among all tractors owned by
 * the given connected wallet. 
 * 
 * When assigning tractors to the farmers, it does not matter which tractor is assignes, as long as it is available.
 * 
 * Additionally - it saves a few steps in the interface - instead of having to choose which tractor to assign,
 * the user just clicks assign button and the first available tractor will be assigned to the given farmer.
 * 
 * 
 * @param {*} connectedWallet 
 * @returns 
 */
export const getFirstAvailableTractor = async(connectedWallet) => {
    try {
        let address = connectedWallet.address
        const balance = await contractTractors.balanceOf(address)
        let firstAvailableTractorId = 0

        if(balance > 0) {
            for (let i = 0; i< balance; i++) {
                let tractorId = await contractTractors.tokenOfOwnerByIndex(address, i)
                let isAssigned = await contractOShmkl.isTractorAssigned(tractorId)
                if(!isAssigned) {
                    firstAvailableTractorId = tractorId;
                    break;
                }
            }
        }
        return Number(firstAvailableTractorId);
    } catch (error) {
        console.error(error)
    }
}