import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography  from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';

export const SingleCard = ({ title, desc, icon, gotoRoute }) => {
    return ( 
        <Card sx={{
            maxWidth: { xs: '100%', sm: '60%', md: '30%', lg: '30%', xl: '15vw' },
            minWidth: { xs: '80%', sm: '60%', md: '40%', lg: '30%', xl: '15vw' },
            m: 1,
        }} elevation={0}>
            <CardContent>
                <Typography variant="h5" component="div">
                    {(icon === 'mint-farmer') && <CurrencyBitcoinIcon sx={{ fontSize: '40px', color: '#9966ff' }} /> }
                    {(icon === 'mint-tractor') && <CurrencyBitcoinIcon sx={{ fontSize: '40px', color: '#9966ff' }} /> }
                    {(icon === 'dashboard') && <CurrencyBitcoinIcon sx={{ fontSize: '40px', color: '#9966ff' }} /> }
                </Typography>
                <Typography sx={{  mb: '1vw' }} variant={"h6"} color="text.secondary" gutterBottom>
                    <Link 
                        to={gotoRoute} 
                        component={RouterLink}
                        sx={
                            {
                                textDecoration: 'none',
                                color: 'primary.main',
                                '&:hover': {
                                    color: 'primary.dark',
                                },
                            }
                        }
                    >{title}</Link>
                </Typography>
                <Typography variant="body2">
                    {desc}
                </Typography>
            </CardContent>
        </Card>
    );
}

SingleCard.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    icon: PropTypes.string,
    gotoRoute: PropTypes.string
}