import React, { useState } from 'react'
import Typography  from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import { connectWallet } from '../utils/web3.js'

export const Login = () => {
    const [loading, setLoading] = useState(false)
    return (
        <>
            <Typography variant="p" paragraph>Welcome to Planet Mode's self-serving space-agro portal.</Typography>
            <Typography variant="p" paragraph>Connect your wallet to proceed.</Typography>
            <LoadingButton variant="contained" sx={{ ml: '10vw', mr: '10vw', my: '3vh'}} loading={loading} onClick={() => {
                setLoading(true)
                connectWallet('eth_requestAccounts').then(result=>{
                    setLoading(false)
                })
            }}>Connect wallet</LoadingButton>
        </>
    );
}