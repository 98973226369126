import PropTypes from 'prop-types'
import React from 'react'
import Container from '@mui/material/Container';
import { MyFarmers } from '../components/MyFarmers.jsx'
import { MyTractors } from '../components/MyTractors.jsx'
import LinearProgress from '@mui/material/LinearProgress';

import { Stats } from '../components/Stats.jsx'
import { FooterMenu } from '../components/FooterMenu.jsx'
import { WrongNetwork } from '../components/WrongNetwork.jsx'
import { TractorProvider } from '../context/TractorContext.jsx';

export const Dashboard = ({connectedWallet, currentBlockNumber}) => {
    return (<>
        {(connectedWallet.connected) && <>
        <Container sx={{
            paddingLeft: '15%',
            paddingRight: '15%',
            width: '100%',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Stats connectedWallet={connectedWallet}/>
            <TractorProvider connectedWallet={connectedWallet}>
                <MyFarmers connectedWallet={connectedWallet} currentBlockNumber={currentBlockNumber}/>
            </TractorProvider>
            <MyTractors connectedWallet={connectedWallet} />
        </Container>
        <FooterMenu />
        <WrongNetwork />
    </>}
    {(!connectedWallet.connected) && <LinearProgress />}
    </>)
}

Dashboard.propTypes = {
    connectedWallet: PropTypes.object,
    currentBlockNumber: PropTypes.number
}