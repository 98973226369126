import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Typography  from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import LoadingButton from '@mui/lab/LoadingButton'
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import ScienceIcon from '@mui/icons-material/Science';
import GrassIcon from '@mui/icons-material/Grass';
import CircleIcon from '@mui/icons-material/Circle';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { useTractors } from '../context/TractorContext.jsx';

import { getSingleFarmerDetails, stakeFarmer, provider, unstakeFarmer, harvestFarmer, costOfFertilizer, oShmklDecimals, provideFertilizer, costOfWater, provideWater, getFirstAvailableTractor, assignTractorToFarmer, contractOShmkl, removeTractorFromFarmer, tractorBoost, defaultRewardCycle } from  '../utils/web3.js'

export const SingleFarmer = ({  farmerDetails: initialFarmerDetails, firstAvailableTractor: initialTractorId, connectedWallet, oShmklBalance }) => {
    const [updateInProgress, setUpdateInProgress] = useState(false)
    const [farmerDetails, setFarmerDetails] = useState(initialFarmerDetails)
    const [errorStaking, setErrorStaking] = useState(false)
    const [whenNextHarvest, setNextHarvestIn] = useState(defaultRewardCycle)
    const [firstAvailableTractor, setFirstAvailableTractor] = useState(initialTractorId)

    const [canWater, setCanWater] = useState(false)
    const [canFertilize, setCanFertilize] = useState(false)

    const { tractors, nextAvailableTractor, reserveTractor, releaseTractorReservation } = useTractors();

    
    useEffect(() => {
        if(oShmklBalance > costOfWater) {
            setCanWater(true)
        } else {
            setCanWater(false)
        }

        if(oShmklBalance > costOfFertilizer) {
            setCanFertilize(true)
        } else {
            setCanFertilize(false)
        }
    }, [oShmklBalance])
    

    useEffect(() => {
        const handleNewBlock = (blockNumber) => {
            if (farmerDetails.is_staked) {
                let nextHarvestIn = farmerDetails.next_harvest_block - blockNumber;
                if (nextHarvestIn < 0) {
                    nextHarvestIn = 0;
                }
                setNextHarvestIn(nextHarvestIn);
            }
        };
    
        const handleTractorRemoved = (farmerId, tractorId, tractorOwner) => {
            if (tractorOwner === connectedWallet.address) {
                getFirstAvailableTractor(connectedWallet).then(nextTractor => {
                    setFirstAvailableTractor(nextTractor);
                    if (Number(farmerId) === farmerDetails.farmer_id) {
                        updateFarmerDetails(1, farmerDetails.farmer_id).then(() => {});
                    }
                });
            }
        };
    
        const handleTractorAssigned = (farmerId, tractorId, tractorOwner) => {
            if (tractorOwner === connectedWallet.address) {
                getFirstAvailableTractor(connectedWallet).then(nextTractor => {
                    setFirstAvailableTractor(nextTractor);
                });
            }
        };
    
        const handleFarmerHarvest = (eventFarmerId, farmerOwner, harvestAmount, newBalance) => {
            if (Number(eventFarmerId) === farmerDetails.farmer_id) {
                updateFarmerDetails(1, eventFarmerId).then(() => {});
            }
        };
    
        const handleFarmerStaked = (farmerId, farmerOwner) => {
            if (Number(farmerId) === farmerDetails.farmer_id) {
                updateFarmerDetails(1, farmerId).then(() => {});
            }
        };
        
        provider.off('block', handleNewBlock);
        contractOShmkl.off('TractorRemoved', handleTractorRemoved);
        contractOShmkl.off('TractorAssigned', handleTractorAssigned);
        contractOShmkl.off('FarmerHarvest', handleFarmerHarvest);
        contractOShmkl.off('FarmerStaked', handleFarmerStaked);

        provider.on('block', handleNewBlock);
        contractOShmkl.on('TractorRemoved', handleTractorRemoved);
        contractOShmkl.on('TractorAssigned', handleTractorAssigned);
        contractOShmkl.on('FarmerHarvest', handleFarmerHarvest);
        contractOShmkl.on('FarmerStaked', handleFarmerStaked);
        
        // Cleanup function to unsubscribe from events does not work for some reason - thats why cleanup/subscribe happens inside useEffect block.
    }, [farmerDetails]);

    const assignTractor = async() => {

        let tractorId = nextAvailableTractor
        reserveTractor(tractorId)
        setUpdateInProgress(true)
        setErrorStaking(false)
        assignTractorToFarmer(farmerDetails.farmer_id, tractorId).then(result => {
            if(result === 0) {
                releaseTractorReservation(tractorId)
            } 
            updateFarmerDetails(result, farmerDetails.farmer_id).then(x=>{})
            
        })
    }

    const releaseTractor = async() => {
        setUpdateInProgress(true)
        setErrorStaking(false)
        removeTractorFromFarmer(farmerDetails.farmer_id, farmerDetails.assigned_tractor_id).then(result => {
            updateFarmerDetails(result, farmerDetails.farmer_id).then(x=>{})
        })
    }

    const fertilize = async (farmerId) => {
        setUpdateInProgress(true)
        setErrorStaking(false)
        provideFertilizer(farmerId).then(result => {
            updateFarmerDetails(result, farmerId).then(x=>{})
        })
    }

    const water = async (farmerId) => {
        setUpdateInProgress(true)
        setErrorStaking(false)
        provideWater(farmerId).then(result => {
            updateFarmerDetails(result, farmerId).then(x=>{})
        })
    }

    const stake = async (farmerId) => {
        setUpdateInProgress(true)
        setErrorStaking(false)
        stakeFarmer(farmerId).then(result => {
            //Event triggered will get this done
            //updateFarmerDetails(result, farmerId).then(x=>{})
        })
    }

    const harvest = async (farmerId) => {
        setUpdateInProgress(true)
        setErrorStaking(false)
        harvestFarmer(farmerId).then(result => {
            //Same here, the component will react on the event and update the farmer
            //updateFarmerDetails(result, farmerId).then(x=>{})
        })
    }

    const unstake = async (farmerId) => {
        setUpdateInProgress(true)
        setErrorStaking(false)
        unstakeFarmer(farmerId).then(result => {
            updateFarmerDetails(result, farmerId).then(x=>{})
        })
    }

    const updateFarmerDetails = async (result, farmerId) => {
        if(result === 1) {
            getSingleFarmerDetails(farmerId).then(newNarmerDetails => {
                setFarmerDetails(newNarmerDetails)
                setUpdateInProgress(false)
            })
        } else {
            setUpdateInProgress(false)
            setErrorStaking(true)
        }
    }
    return ( 
        <Card sx={{
            maxWidth: { xs: '100%', sm: '60%', md: '30%', lg: '30%', xl: '17vw' },
            minWidth: { xs: '80%', sm: '60%', md: '40%', lg: '30%', xl: '15vw' },
            m: 0,
            textAlign: 'left',
            backgroundColor: '#e6e6e6',
            my: '2vh'
        }} elevation={0}>
            <CardMedia
                component="img"
                image={farmerDetails.artwork_thumbnail}
                alt="Modern Finance Farmer"
            />
            {(updateInProgress) && <LinearProgress />}
            <CardContent sx={{
                px: '0.5vw'
            }}>
                <Typography variant="h6" component="div">
                    Farmer #{farmerDetails.farmer_id}
                </Typography>
                <Typography sx={{  mb: '1vw' }} variant={"h6"} color="text.secondary" gutterBottom>
                    <CircleIcon sx={{ fontSize: '1.5rem', color: farmerDetails.is_staked ? 'green' : 'red', mr: '0.5vw' }} alt={"Staked"}/>
                    <AgricultureIcon sx={{ fontSize: '1.5rem', color: farmerDetails.assigned_tractor_id > 0 ? 'green' : 'red', mr: '0.5vw' }} />
                    <WaterDropIcon sx={{ fontSize: '1.5rem', color: farmerDetails.is_water_provided ? 'blue' : 'red', mr: '0.5vw' }}/>
                    <ScienceIcon sx={{ fontSize: '1.5rem', color: farmerDetails.is_fertilizer_provided ? 'green' : 'red', mr: '0.5vw' }}/>
                    {(farmerDetails.is_staked) && <GrassIcon sx={{ fontSize: '1.5rem', color: whenNextHarvest === 0 ? 'green': 'orange', mr: '0.5vw' }}/>}
                    
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    {(!updateInProgress && farmerDetails.is_staked) && <>
                        <Typography variant={"customVariant"} sx={{ mb: 1 }} gutterBottom>Next Harvest of {farmerDetails.harvestable_amount} oSHMKL in {whenNextHarvest} blocks</Typography>
                        {(farmerDetails.assigned_tractor_id > 0) && <>
                            <Typography variant={"customVariantHeading"} sx={{ mb: 1 }} gutterBottom>Assigned Tractor #{farmerDetails.assigned_tractor_id}</Typography>
                            <Typography variant={"customVariant"} sx={{ mb: 2 }}>Your harvest will be {tractorBoost} blocks faster because this farmer has a tractor assigned to it.</Typography>
                        </>}

                        <Typography variant={"customVariantHeading"} sx={{ mb: 1 }} gutterBottom>Base reward: {farmerDetails.base_reward} oSHMKL</Typography>
                        {(!farmerDetails.is_water_provided) && <>
                            <Typography variant={"customVariant"}>Add water for {(Number(costOfWater)/10**oShmklDecimals)} oSHMKL to keep your base reward on optimal level.</Typography>
                            {canWater && <LoadingButton size="small" sx={{ mb: 2 }} onClick={()=>water(farmerDetails.farmer_id)} disabled={updateInProgress}>Provide Water</LoadingButton>}
                        </>}

                        <Typography variant={"customVariantHeading"} sx={{ mb: 1 }} gutterBottom>Fertilizer boost: {farmerDetails.fertilizer_boost}x</Typography>
                        {(!farmerDetails.is_fertilizer_provided && whenNextHarvest >0) && <>
                            <Typography variant={"customVariant"}>Add fertilizer for {(Number(costOfFertilizer)/10**oShmklDecimals)} oSHMKL to 2x boost your yield</Typography>
                            {canFertilize && <LoadingButton size="small" onClick={()=>fertilize(farmerDetails.farmer_id)} disabled={updateInProgress}>Add Fertilizer</LoadingButton>}
                        </>}
                    </>}
                    {(!updateInProgress && !farmerDetails.is_staked) && <>
                        <Typography variant={"customVariant"} sx={{ mb: 1 }} gutterBottom>Farmer not staked.</Typography>
                    </>}
                    {(errorStaking) && <Alert severity="error">Transaction canceled or something went wrong.</Alert>}
                </Box>
            </CardContent>
            <CardActions>
                {(!updateInProgress) && <>
                    {(!farmerDetails.is_staked) && <LoadingButton size="small" disabled={updateInProgress} onClick={()=>stake(farmerDetails.farmer_id)}>Stake</LoadingButton>}
                    {(farmerDetails.is_staked) && <LoadingButton size="small" disabled={updateInProgress} onClick={()=>unstake(farmerDetails.farmer_id)}>Unstake</LoadingButton>}
                    
                    {(farmerDetails.assigned_tractor_id > 0) && <LoadingButton size="small" disabled={updateInProgress} onClick={()=>releaseTractor()}>Release Tractor</LoadingButton>}
                    {(farmerDetails.assigned_tractor_id === 0 && firstAvailableTractor !== 0) && <LoadingButton size="small" disabled={updateInProgress} onClick={()=>assignTractor()}>Add Tractor</LoadingButton>}

                    {(whenNextHarvest === 0 && farmerDetails.is_staked) && <LoadingButton size="small" disabled={updateInProgress} onClick={()=>harvest(farmerDetails.farmer_id)}>Harvest</LoadingButton>}
                </>}
           </CardActions>
        </Card>
    );
}

SingleFarmer.propTypes = {
    farmerDetails: PropTypes.object,
    currentBlockNumber: PropTypes.number,
    firstAvailableTractor: PropTypes.number,
    connectedWallet: PropTypes.object,
    oShmklBalance: PropTypes.number
}