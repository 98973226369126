import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Typography  from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import { Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import { stakeAllFarmers, contractOShmkl, getFarmerStats } from  '../utils/web3.js'

export const StatsFarmers = ({ connectedWallet }) => {
    const [errorLoadingStats, setErrorLoadingStats] = useState(false)
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [updateInProgress, setUpdateInProgress] = useState(false)
    const [totalFarmers, setTotalFarmers] = useState(0)
    const [stakedFarmers, setStakedFarmers] = useState(0)

    useEffect(()=>{
        setIsDataLoaded(false)
    },[connectedWallet])
    
    const updateFarmerStats = async () => {
        try {
            getFarmerStats(connectedWallet).then(stats=>{
                setTotalFarmers(stats.total)
                setStakedFarmers(stats.staked)
            })
        } catch (error) {
            setErrorLoadingStats(true)
        }
    }

    useEffect(() => {
        // Define event handlers
        const handleFarmerUnstaked = (farmerId, farmerOwner) => {
            if (farmerOwner === connectedWallet.address) {
                console.log("Farmer Unstaked", farmerId, farmerOwner);
                setErrorLoadingStats(false);
                setUpdateInProgress(true);
                updateFarmerStats().then(() => {
                    setUpdateInProgress(false);
                });
            }
        };
    
        const handleFarmerStaked = (farmerId, farmerOwner) => {
            if (farmerOwner === connectedWallet.address) {
                console.log("Farmer Staked", farmerId, farmerOwner);
                setErrorLoadingStats(false);
                setUpdateInProgress(true);
                updateFarmerStats().then(() => {
                    setUpdateInProgress(false);
                });
            }
        };
        
        //Cleanup
        contractOShmkl.off('FarmerUnstaked', handleFarmerUnstaked);
        contractOShmkl.off('FarmerStaked', handleFarmerStaked);
        // Subscribe to events
        contractOShmkl.on('FarmerUnstaked', handleFarmerUnstaked);
        contractOShmkl.on('FarmerStaked', handleFarmerStaked);
    }, []);

    if(!isDataLoaded) {
        updateFarmerStats().then(x=> {
            setIsDataLoaded(true)
        })
    }

    const stakeAll = async() => {
        setErrorLoadingStats(false)
        setUpdateInProgress(true)
        stakeAllFarmers().then(result=>{
            if(result === 1) {
                updateFarmerStats().then(x=>{
                    setUpdateInProgress(false)
                })
            } else {
                setErrorLoadingStats(true)
                setUpdateInProgress(false)
            }
        })
    }
    
    return ( 
        <Card sx={{
            maxWidth: { xs: '100%', sm: '60%', md: '30%', lg: '30%', xl: '10vw' },
            minWidth: { xs: '80%', sm: '60%', md: '40%', lg: '30%', xl: '10vw' },
            m: 0,
            textAlign: 'center',
            backgroundColor: '#e6e6e6',
            my: '2vh'
        }} elevation={0}>

            {(!isDataLoaded || updateInProgress) && <LinearProgress />}
            <CardContent sx={{
                px: '0.5vw'
            }}>
                <Typography variant="customVariant" component="div">
                    My Farmers
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {(isDataLoaded) && <>
                        <Typography variant={"h6"} sx={{ mb: 0 }}>{totalFarmers}</Typography>
                        <Typography variant={"customVariant"} sx={{ mb: 1 }} gutterBottom> {stakedFarmers} staked Farmers</Typography>
                    </>}
                    {(totalFarmers > stakedFarmers) && <LoadingButton size="small" onClick={()=>stakeAll()} disabled={updateInProgress}>Stake All</LoadingButton>}
                {(totalFarmers === 0) && <Link 
                        to={'/modearn-farmers'} 
                        component={RouterLink}
                        variant="customVariantFooterLink"
                    >{"Mint a Farmer"}</Link>}
                    {(errorLoadingStats) && <Alert severity="error">Stats could not be loaded.</Alert>}
                </Box>
            </CardContent>
        </Card>
    );
}

StatsFarmers.propTypes = {
    connectedWallet: PropTypes.object
}