import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Typography  from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import { SingleTractor } from './SingleTractor.jsx'
import { getMyTractors } from  '../utils/web3.js'

export const MyTractors = ({ connectedWallet, currentBlockNumber }) => {
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [myTractors, setMyTractors] = useState([])
    const [assignedTractors, setAssignedTractors] = useState([])
    const [notAssignedTractors, setNotStakedTractors] = useState([])

    useEffect(()=>{
        setIsDataLoaded(false)
    },[connectedWallet])

    if(!isDataLoaded) {
        getMyTractors(connectedWallet).then( allTractors => {
            let tractors = []
            if(allTractors.length > 0) {
                let i = 0
                for(const singleTractorData of allTractors) {
                    tractors.push(<SingleTractor tractorDetails={singleTractorData} key={i}/>)
                    i++
                }
            }
            setMyTractors(tractors)

            //Filter items, just in case we want to provide filters in the interface - to allow displaying all/staked only/not staked only
            let assignedTractorsFilter = allTractors.filter(tractor => tractor.is_assigned)
            let notAssignedTractorsFilter = allTractors.filter(tractor => !tractor.is_assigned)

            setAssignedTractors(assignedTractorsFilter)
            setNotStakedTractors(notAssignedTractorsFilter)
            setIsDataLoaded(true)
        })
    }

    return (
        <Box sx={{
            width: '100%',
            my: '3vh',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            mx: { xs: '5%', sm: '10%', md: '20%', lg: '25%' },
       }}>                
           <Paper elevation={15} style={{ width: '100%', margin: '20px', padding: '20px', textAlign: 'center' }}>
               <Typography variant="h5" gutterBottom>
                   SS Historia Terminal - Planet Mode
               </Typography>
               <Typography variant="h3" gutterBottom>
                   My Tractors
               </Typography>
                <Typography variant="p" paragraph>Farm oSHMKL faster with tractors.</Typography>
                {(!isDataLoaded) && <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', px: '5%', my: '2vw', alignItems: { xs: 'center', sm: 'center' }}}>
                        <Typography variant="p" paragraph>Loading Tractors</Typography>
                        <CircularProgress />
                    </Box>
                }
                {(isDataLoaded && myTractors.length > 0) && <Box sx={{display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', px: '5%', my: '2vw', alignItems: { xs: 'center', sm: 'flex-start' },  flexWrap: 'wrap',}}>
                    {myTractors}
                    </Box>
                }
                {(isDataLoaded && myTractors.length === 0) && <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', px: '5%', my: '2vw', alignItems: 'center',  flexWrap: 'wrap',}}>
                    <Typography variant="customVariantHeading" gutterBottom>
                        No tractors detected.
                    </Typography>
                    <Typography sx={{  mb: '1vw' }} variant={"h6"} color="text.secondary" gutterBottom>
                        <Link 
                            to={'/modearn-tractors'} 
                            component={RouterLink}
                            sx={
                                {
                                    textDecoration: 'none',
                                    color: 'primary.main',
                                    '&:hover': {
                                        color: 'primary.dark',
                                    },
                                }
                            }
                        >{"Mint a Tractor"}</Link>
                    </Typography>
                    </Box>
                }
            </Paper>
        </Box>
    );
}

MyTractors.propTypes = {
    connectedWallet: PropTypes.object,
    currentBlockNumber: PropTypes.number
}